// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DefaultPageLayout-module__ZZMG_VdpTmbEvZsH8S4g{width:100%;height:100%;display:flex;flex-direction:column}.DefaultPageLayout-module__Q_mseCF3KAZqmexrrpg6{display:flex;flex-direction:column;margin-bottom:24px;gap:10px}.DefaultPageLayout-module__Q_mseCF3KAZqmexrrpg6:empty{display:none}.DefaultPageLayout-module__ur2Tqir3gzXmfu25CN9P .DefaultPageLayout-module__Q_mseCF3KAZqmexrrpg6{padding-top:10px}.DefaultPageLayout-module__VgVZ1u2kqEaSCle5cMLl{margin:0}@media(max-width: 768px){.DefaultPageLayout-module__ur2Tqir3gzXmfu25CN9P{padding-top:50px}}.DefaultPageLayout-module__ZZMG_VdpTmbEvZsH8S4g .filter-table td{white-space:break-spaces;line-height:20px;height:auto}.DefaultPageLayout-module__I6QTvlo03GYEUBdW1ImC{color:var(--primary-text-link)}", "",{"version":3,"sources":["webpack://./containers/DefaultPageLayout/DefaultPageLayout.module.scss"],"names":[],"mappings":"AAAA,gDACE,UAAA,CACA,WAAA,CACA,YAAA,CACA,qBAAA,CAGF,gDACE,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,QAAA,CAEA,sDACE,YAAA,CAIJ,gGACE,gBAAA,CAGF,gDACE,QAAA,CAGF,yBACE,gDACE,gBAAA,CAAA,CAMJ,iEACE,wBAAA,CACA,gBAAA,CACA,WAAA,CAGF,gDACE,8BAAA","sourcesContent":[".root {\n  width: 100%;\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n}\n\n.alerts-container {\n  display: flex;\n  flex-direction: column;\n  margin-bottom: 24px;\n  gap: 10px;\n\n  &:empty {\n    display: none;\n  }\n}\n\n.navbar-legacy .alerts-container {\n  padding-top: 10px;\n}\n\n.alert {\n  margin: 0;\n}\n\n@media (max-width: 768px) {\n  .navbar-legacy {\n    padding-top: 50px;\n  }\n}\n\n/* --- GRAFANA UI TUNINGS --- */\n\n.root :global(.filter-table) td {\n  white-space: break-spaces;\n  line-height: 20px;\n  height: auto;\n}\n\n.instructions-link {\n  color: var(--primary-text-link);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "DefaultPageLayout-module__ZZMG_VdpTmbEvZsH8S4g",
	"alerts-container": "DefaultPageLayout-module__Q_mseCF3KAZqmexrrpg6",
	"navbar-legacy": "DefaultPageLayout-module__ur2Tqir3gzXmfu25CN9P",
	"alert": "DefaultPageLayout-module__VgVZ1u2kqEaSCle5cMLl",
	"instructions-link": "DefaultPageLayout-module__I6QTvlo03GYEUBdW1ImC"
};
export default ___CSS_LOADER_EXPORT___;
