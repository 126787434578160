// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Schedules-module__SU3YOIZNGwjo6D6caZYp {\n  position: relative;\n  margin: 20px 0;\n  max-width: calc(100vw - 104px);\n}\n\n.Schedules-module__wRZ7YKJYxUGOk7Q5yKvR {\n  margin-bottom: var(--title-marginBottom);\n}\n\n.Schedules-module__NVJTrOWGZI7bI1pn3hsV .Schedules-module__NZutD9pVFmYCmUHpDHm9 {\n  padding-right: 10px;\n}\n", "",{"version":3,"sources":["webpack://./pages/schedules/Schedules.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,cAAc;EACd,8BAA8B;AAChC;;AAEA;EACE,wCAAwC;AAC1C;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":[".schedule {\n  position: relative;\n  margin: 20px 0;\n  max-width: calc(100vw - 104px);\n}\n\n.title {\n  margin-bottom: var(--title-marginBottom);\n}\n\n.root .buttons {\n  padding-right: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"schedule": "Schedules-module__SU3YOIZNGwjo6D6caZYp",
	"title": "Schedules-module__wRZ7YKJYxUGOk7Q5yKvR",
	"root": "Schedules-module__NVJTrOWGZI7bI1pn3hsV",
	"buttons": "Schedules-module__NZutD9pVFmYCmUHpDHm9"
};
export default ___CSS_LOADER_EXPORT___;
